export default [
  {
    key: "dashboard",
    href: "/",
    title: "Dashboard",
    icon: "bi bi-speedometer2",
  },
  {
    key: "hospitals",
    href: "/hospitals",
    title: "Hospital",
    icon: "bi bi-building",
    permissions: ["view", "add", "update", "delete"],
  },
  {
    key: "orders",
    href: "/orders",
    title: "Orders",
    icon: "bi bi-list-ol",
    permissions: ["view", "add", "update", "delete"],
  },
  {
    key: "products",
    href: "/products",
    title: "Products",
    icon: "bi bi-boxes",
    permissions: ["view", "add", "update", "delete"],
  },
  {
    key: "cart",
    href: "/cart",
    title: "Cart",
    icon: "bi bi-cart",
    permissions: ["view", "add", "update", "delete"],
  },
  {
    key: "collections",
    href: "/collections",
    title: "Collections",
    icon: "bi bi-collection",
    permissions: ["view", "add", "update", "delete"],
  },
  {
    key: "ecomcategories",
    href: "/ecomcategories",
    title: "Ecom categories",
    icon: "bi bi-diagram-2",
    permissions: ["view", "add", "update", "delete"],
  },
  {
    key: "attributes",
    href: "/attributes",
    title: "Attributes",
    icon: "bi bi-diagram-2",
    permissions: ["view", "add", "update", "delete"],
  },
  {
    key: "notifications",
    href: "/notifications",
    title: "Notifications",
    icon: "bi bi-bell-fill",
    permissions: ["view", "add", "update", "delete"],
  },
  {
    key: "user",
    href: "/users",
    title: "Users",
    icon: "bi bi-people",
    permissions: ["view", "add", "update", "delete"],
  },
  {
    key: "vendor",
    href: "/vendors",
    title: "Vendors",
    icon: "bi bi-people",
    permissions: ["view", "add", "update", "delete"],
  },
  {
    key: "guestlogin",
    href: "/guestlogin",
    title: "Guest-login",
    icon: "bi bi-people",
    permissions: ["view", "add", "update", "delete"],
  },
  {
    key: "page",
    href: "/pages",
    title: "Pages",
    icon: "bi bi-file-earmark-richtext",
    permissions: ["view", "add", "update", "delete"],
  },
  {
    key: "notificationtemplate",
    href: "/notificationtemplate",
    title: "Notification Templates",
    icon: "bi bi-bell",
    permissions: ["view", "add", "update", "delete"],
  },
  {
    key: "team",
    href: "/teams",
    title: "Team",
    icon: "bi bi-person",
    permissions: ["view", "add", "update", "delete"],
  },
  {
    key: "role",
    href: "/roles",
    title: "Role & Permission",
    icon: "bi bi-key",
    permissions: ["view", "add", "update", "delete"],
  },
  {
    key: "reasons",
    href: "/reasons",
    title: "Reasons",
    icon: "bi bi-x-circle",
    permissions: ["view", "add", "update", "delete"],
  },
  {
    key: "settings",
    href: "/settings",
    title: "Settings",
    icon: "bi bi-gear",
    permissions: ["view", "add", "update", "delete"],
  },
  {
    key: "homepagelayout",
    href: "/homepagelayout",
    title: "Homepage Layout",
    icon: "bi bi-layout-split",
    permissions: ["view", "add", "update", "delete"],
  },
  {
    key: "brands",
    href: "/brands",
    title: "brands",
    icon: "bi bi-stopwatch",
    permissions: ["view", "add", "update", "delete"],
  },
  {
    key: "offers",
    href: "/offers",
    title: "offers",
    icon: "bi bi-tag",
    permissions: ["view", "add", "update", "delete"],
  },
  {
    key: "giftcard",
    href: "/giftcards",
    title: "giftcards",
    icon: "bi bi-receipt",
    permissions: ["view", "add", "update", "delete"],
  },
  {
    key: "giftcard-order",
    href: "/giftcard-orders",
    title: "giftcard-orders",
    icon: "bi bi-receipt",
    permissions: ["view", "add", "update", "delete"],
  },
];
